import React, { useContext, useState, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import useSound from "use-sound"
import doneSfx from "../../static/sounds/done.mp3"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleKeyboard from "../components/ToggleKeyboard"
import ToggleMute from "../components/ToggleMute"

export default function Credentials() {
  const { user, saveUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")

  const [playDone] = useSound(doneSfx)

  const API_URL = process.env.GATSBY_API_URL
  const [submitText, setSubmitText] = useState("Finish")
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  const registerUser = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "access-control-allow-origin, Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      },
      body: JSON.stringify(user),
    }
    fetch(API_URL + "/register-kiosk", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then(data => {
        playDone()
        user.audio && user.audio.stop && user.audio.stop()
        alert(data.message)
        setSubmitDisabled(false)
        setSubmitText("Finish")

        //Reset values
        saveUser({
          nric: "", //unique id
          name: "",
          email: "",
          password: "",
          mobile_number: "",
          casual_labour_role: false,
          cleaning_specialist_role: false,
          nric_front: null, //base64 image
          nric_back: null, //base64 image
          face: null, //base64 image
          birth_date: "",
          gender: "",
          nationality: "",
          singapore_pr: "",
          civil_status: "",
          race: "",
          religion: "",
          singapore_pr_date: "",
          no_of_children: 0,
          address: "",
          residential_number: "",
          residential_phone_no: "",
          nok_agree: false,
          person_in_charge_id: 0,
          nok_name: "",
          nok_relationship: "",
          nok_birth_date: "",
          nok_residential_address: "",
          nok_residential_number: "",
          nok_mobile_number: "",
          highest_edu_school: "",
          highest_edu_years: "",
          highest_edu_qualification: "",
          current_edu_school: "",
          current_edu_years: "",
          current_edu_qualification: "",
          currentPage: 1,
          appendix1_option: "No",
          appendix1_details: "",
          appendix2_option: "No",
          appendix2_details: "",
          appendix3_option: "No",
          appendix3_details: "",
          appendix4_option: "No",
          appendix4_details: "",
          substitute_name: "",
          substitute_contractor_code: "",
          substitute_contact_number: "",
          salary_payment_method: "Cash",
          feedback: "",
          signature: null,
        })

        navigate("/finish")
      })
      .catch(err => {
        // console.error(err)
        err.text().then(errorMessage => {
          alert(errorMessage)
          setSubmitDisabled(false)
          setSubmitText("Finish")
        })
      })
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-3 font-weight-bold mt-4 ml-4">One Last Step</h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          Please input your e-mail and password for the app.
        </h1>

        <div className="row mb-4 ml-4">
          <h3 className="col-3 form-check-label font-weight-bold mt-4">
            E-mail
          </h3>
          <div className="col-3">
            <input
              type="text"
              name="email"
              className="form-control"
              autoComplete="off"
              onFocus={() => setInputName("email")}
              style={{
                transform: "scale(2)",
                marginTop: "2em",
              }}
              value={user.email}
              onChange={e => saveField(e)}
            />
          </div>
        </div>

        <div className="form-group row ml-4">
          <h3 className="col-3 form-check-label font-weight-bold mt-4">
            Password
          </h3>
          <div className="col-3">
            <input
              type="password"
              name="password"
              className="form-control"
              onFocus={() => setInputName("password")}
              autoComplete="off"
              style={{
                transform: "scale(2)",
                marginTop: "2em",
              }}
              value={user.password}
              onChange={e => saveField(e)}
            />
          </div>
        </div>

        <p className="text-muted ml-4 mt-4">
          You can change this later via app.
        </p>
        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleKeyboard
            isActive={user.isKeyboardActive}
            onClick={() => {
              saveUser({
                ...user,
                isKeyboardActive: !user.isKeyboardActive,
              })
            }}
          />
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>
        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text={submitText}
            size="medium"
            disabled={submitDisabled}
            onClick={() => {
              setSubmitText("Please wait...")
              setSubmitDisabled(true)
              registerUser()
            }}
          />
          <Button
            size="medium"
            text="Back"
            disabled={submitDisabled}
            onClick={() =>
              navigate(
                user.salary_payment_method === "Cash"
                  ? "/payout-option-cash"
                  : "/payout-option-giro"
              )
            }
          />
        </div>
        {user.isKeyboardActive && (
          <div
            className="fixed-bottom w-50 p-4"
            style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
      </div>
    </Background>
  )
}
